/* @flow */

export const OPT_IN_STEP = {
    QUIZ:          ('quiz' : 'quiz'),
    PHONE_INPUT:   ('phone_input' : 'phone_input'),
    EMAIL_INPUT:   ('email_input' : 'email_input'),
    SUCCESS:       ('success' : 'success')
};

export const CHECKPOINT = {
    POPUP_RENDERED:                      ('onetext_popup_rendered' : 'onetext_popup_rendered'),
    QUIZ_CONFIRMATION:                   ('onetext_quiz_confirmation' : 'onetext_quiz_confirmation'),
    PHONE_SUBMISSION_SUCCESS:            ('onetext_phone_submission_success' : 'onetext_phone_submission_success'),
    MULTIFACTOR_CODE_SUBMISSION_SUCCESS: ('onetext_multifactor_code_submission_success' : 'onetext_multifactor_code_submission_success'),
    EMAIL_SUBMISSION_SUCCESS:            ('onetext_email_submission_success' : 'onetext_email_submission_success'),
    SUCCESS_PAGE_RENDERED:               ('onetext_success_page_rendered' : 'onetext_success_page_rendered')
};

