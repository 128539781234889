/* @flow */
import { associateShopperAndStoreLocally } from '../api';

function checkPhoneNumberFormat(phone : string) : boolean {
    const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
    return phoneNumberRegex.test(phone);
}

function debounce(
    func : Function,
    delay : number
) : (...args: $ReadOnlyArray<mixed>) => void {
    let timeoutId;

    function callback(...args : $ReadOnlyArray<mixed>) : void {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    }

    return callback;
}

function hasPhoneAttributes(input : HTMLElement) : boolean {
    /* ::
    if (!(input instanceof HTMLInputElement)) {
        throw new Error('element is not of type HTMLInputElement');
    }
    */
    const ariaLabel = input ? input.getAttribute('aria-label') : '';
    return input.name.toLowerCase().includes('phone')
            || input.id.toLowerCase().includes('phone')
            || (typeof ariaLabel === 'string' && ariaLabel.toLowerCase().includes('phone'));
}

type ListenForPhoneInputHandlerSignature = {|
    element : HTMLInputElement,
    phone : string
|};

type ListenForPhoneInputOptions = {|
    selector : string,
    cartToken : string,
    rootElemenet ? : Node,
    handler ? : (ListenForPhoneInputHandlerSignature) => void
|};

export function listenForPhoneInput({ selector, cartToken, rootElement = document.body, handler } : ListenForPhoneInputOptions) : void {
    const observer = new MutationObserver(() => {
        const formInputs = document.querySelectorAll(selector);

        if (formInputs.length > 0) {
            // Spread operator works in browser but weird transpilation of node list incorrectly
            // yields a list of lists, hence using Array.from instead
            // eslint-disable-next-line unicorn/prefer-spread
            const phoneFields = Array.from(formInputs).filter(input => hasPhoneAttributes(input));

            phoneFields.forEach((phoneField : HTMLElement) => {
                phoneField.addEventListener('blur', debounce((event : Event) => {
                    const { target } = event;
                    if (!(target instanceof HTMLInputElement)) {
                        return;
                    }
                    const inputValue = target.value;
                    if (checkPhoneNumberFormat(inputValue)) {
                        const phone = inputValue.replace(/-/g, '');
                        if (handler) {
                            handler({
                                phone,
                                element: target
                            });
                        }
                        associateShopperAndStoreLocally({ phone, cartToken });
                        observer.disconnect();
                    }
                }, 500));
            });
        }
    });

    observer.observe(rootElement, {
        childList: true,
        subtree:   true
    });
}
