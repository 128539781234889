/* @flow */

import { callOneTextAPI, type CallOneTextAPIResult } from './api';

type SubmitOptInOptions = {|
    accountToken? : string,
    phone? : string,
    email? : string,
    twoFactorCode? : string,
    requestTwoFactorCode? : boolean,
    customFlowParameters? : {
        [ key : string ] : mixed
    },
    forceTwoFactorCode? : boolean,
    immediatelyInvokeCoreWelcomeFlow? : boolean
|};

type SubmitOptInResponse = {|
    accountToken : string,
    phone ? : string,
    email ? : string
|};

export const submitOptIn = ({
    accountToken,
    phone,
    email,
    twoFactorCode,
    customFlowParameters,
    requestTwoFactorCode = false,
    forceTwoFactorCode = false,
    immediatelyInvokeCoreWelcomeFlow = true
} : SubmitOptInOptions) : Promise<CallOneTextAPIResult<SubmitOptInResponse>> => {
    return callOneTextAPI({
        path: 'optin',
        body: {
            accountToken,
            phone,
            email,
            customFlowParameters,
            code:           twoFactorCode,
            requestTwoFactorCode,
            forceTwoFactorCode,
            invokeCoreFlow: immediatelyInvokeCoreWelcomeFlow
        }
    });
};
