/* @flow */
/** @jsx h */

import { h } from 'preact';
import QRC from 'qrcode-svg/dist/qrcode.min';

type QRCodeProps = {|
    url : string,
    size : number
|};

export const QRCode = ({
    url,
    size
} : QRCodeProps) : mixed => {
    return (
        <a
            href={ url }
            ref={ (element) => {
                if (element) {
                    const qr = new QRC({
                        content:    url,
                        padding:    0,
                        width:      size,
                        height:     size,
                        color:      '#000000',
                        background: '#ffffff'
                    });

                    element.innerHTML = qr.svg();
                }
            } }
        />
    );
};
