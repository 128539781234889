/* @flow */
import { isShopifyStore } from '../partner';

type ShopifyCart = {|
    token ? : string
|};

export const fetchShopifyCart = async () : Promise<string | void> => {
    if (isShopifyStore()) {
        return await fetch(`${ window.Shopify.routes.root ?? '/' }cart.js`, {
            method:  'POST',
            headers: {
                'Accept':       'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            return res.json();
        }).then((cart : ShopifyCart) => {
            return cart.token;
        });
    }
};
