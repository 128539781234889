/* @flow */

import { LOCALSTORAGE_KEY, QUERY_PARAM } from '../constants';

import { isLocalStorageEnabled } from './util';

export function getAccountToken() : ?string {
    const localStorageEnabled = isLocalStorageEnabled();

    const queryString = new URLSearchParams(window.location.search);
    const accountToken = queryString.get(QUERY_PARAM.ONETEXT_ACCOUNT_TOKEN)
    ?? queryString.get(QUERY_PARAM.ONETEXT_ACCOUNT_TOKEN_LEGACY)
    ?? (localStorageEnabled ? localStorage.getItem(LOCALSTORAGE_KEY.ONETEXT_ACCOUNT_TOKEN) : undefined);

    return accountToken;

}

export function storeAccountLocally(accountToken : string) : void {
    const localStorageEnabled = isLocalStorageEnabled();
    if (accountToken && localStorageEnabled) {
        localStorage.setItem(LOCALSTORAGE_KEY.ONETEXT_ACCOUNT_TOKEN, accountToken);
    }
}

