/* @flow */

import { ENV, SDK_ATTRIBUTE, SDK_QUERY } from '../constants';

import { assertExists, getCurrentScript, tokenToEnv } from './util';

export function getSDKScript() : HTMLScriptElement {
    return __TEST__
        // $FlowFixMe
        ? assertExists('SDK Script', document.querySelector('#test-sdk-script'))
        : getCurrentScript();
}

export function getSDKUrl() : string {
    return getSDKScript().src;
}

export function getSDKDomain() : string {
    const url = new URL(getSDKUrl());
    return `${ url.protocol }//${ url.host }`;
}

export function getSDKAttribute(name : string) : ?string {
    const value = getSDKScript().getAttribute(name);
    return value ?? undefined;
}

export function getSDKScriptQueryParams() : URLSearchParams {
    const scriptSrc = getSDKAttribute('src');
    if (!scriptSrc || !scriptSrc.match(/^https?:\/\//)) {
        return new URLSearchParams();
    }
    const url = new URL(scriptSrc);
    return url.searchParams;
}

export function getSDKQueryParam(name : string) : string | void {
    const queryParams = getSDKScriptQueryParams();
    return queryParams.get(name) ?? undefined;
}

export function getSDKAccountToken() : string | void {
    return (
        getSDKAttribute(SDK_ATTRIBUTE.ACCOUNT_TOKEN) ??
        getSDKQueryParam(SDK_QUERY.ACCOUNT_TOKEN)
    );
}

export function getSDKClientID() : string | void {
    return (
        getSDKAttribute(SDK_ATTRIBUTE.CLIENT_ID) ??
        getSDKQueryParam(SDK_QUERY.CLIENT_ID)
    );
}

export function getSDKEnv() : $Values<typeof ENV> {
    const sdkEnv = getSDKAttribute(SDK_ATTRIBUTE.ENV);

    if (sdkEnv) {
        // $FlowFixMe
        return sdkEnv;
    }

    const sdkAccountToken = getSDKAccountToken();

    if (sdkAccountToken) {
        return tokenToEnv(sdkAccountToken);
    }

    const sdkClientID = getSDKClientID();

    if (sdkClientID) {
        return tokenToEnv(sdkClientID);
    }

    throw new Error(`Can not determine SDK environment`);
}

type SDKScriptData = {|
    src : string,
    attributes : {
        [ attr : string ] : string | void
    }
|};

export function getSDKScriptData() : SDKScriptData {
    return {
        src:        getSDKUrl(),
        attributes: {
            [ SDK_ATTRIBUTE.ACCOUNT_TOKEN ]: getSDKAccountToken(),
            [ SDK_ATTRIBUTE.CLIENT_ID ]:     getSDKClientID()
        }
    };
}
