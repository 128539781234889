/* @flow */

import { getSDKAccountToken, getSDKClientID, getSDKDomain, promiseTry } from '../lib';

const HTTP_METHOD = {
    GET:  'GET',
    POST: 'POST'
};

type CallOneTextAPIOptions = {|
    method? : $Values<typeof HTTP_METHOD>,
    path : string,
    body ? : mixed
|};

export type CallOneTextAPIResult<ResponseBodyType> = {|
    body : ResponseBodyType
|};

export const callOneTextAPI = <ResponseBodyType>({
    method = HTTP_METHOD.POST,
    path,
    body
} : CallOneTextAPIOptions) : Promise<CallOneTextAPIResult<ResponseBodyType>> => {
    const username = getSDKAccountToken() ?? getSDKClientID();

    return fetch(`${ getSDKDomain() }/api/${ path }?env=production`, {
        method,
        headers: {
            'content-type':  'application/json',
            'authorization': username
                ? `Basic ${ btoa(`${ username }:`) }`
                : ''
        },
        body: JSON.stringify(body)
    }).then(res => {
        if (!res.ok) {
            throw new Error(`One Text API call to ${ path } failed with status ${ res.status }`);
        }

        return res.json().then(json => {
            return {
                body: json
            };
        });
    });
};

type ConstructOneTextURLOptions = {|
    path : string,
    query? : {
        [ key : string ] : string
    }
|};

export const constructOneTextURL = ({
    path, query
} : ConstructOneTextURLOptions) : string => {
    const queryString = (new URLSearchParams(query)).toString();
    return `${ getSDKDomain() }/${ path }?${ queryString }`;
};

type RedirectInPopupOptions = {|
    url : string
|};

export const redirectInPopup = ({ url } : RedirectInPopupOptions) : Promise<void> => {
    return promiseTry(() => {
        window.open(url, '_blank');
    });
};
