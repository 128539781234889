/* @flow */
/* eslint max-lines: off */
/** @jsx h */

import { Fragment, h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { submitOptIn } from '../../api';
import { associateAccountToShopifyCart, getCartToken } from '../../cart';
import { renderComponent } from '../../component';
import { LOCALSTORAGE_KEY } from '../../constants';
import { getAccountToken, isLocalStorageEnabled, promiseTry, storeAccountLocally } from '../../lib';
import { LoadingDots } from '../loading-dots';

import type { BrandModalConfiguration, CustomClasses } from './types';

import { CHECKPOINT, OPT_IN_STEP } from './constants';
import { OPT_IN_MODAL_STYLE } from './style';
import { fireCheckpointEvent, TrackingProvider, useTracking } from './tracking';

type MultifactorCodeInputProps = {|
    value : string,
    setValue : (string) => void,
    multifactorSuccess : boolean
|};

const MultifactorCodeInput = ({ value, setValue, multifactorSuccess } : MultifactorCodeInputProps) : mixed => {
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleChange = (e) => {
        const sanitizedValue = e.target.value.replace(/\D/g, '').slice(0, 4);
        setValue(sanitizedValue);
    };

    useEffect(() => {
        const handleFocus = () => {
            // leaving this for now until best method to focus is determined
        };

        const otpInput = inputRef.current;
        otpInput.addEventListener('focus', handleFocus);

        return () => {
            otpInput.removeEventListener('focus', handleFocus);
        };
    }, []);

    const boxStyles = (index) => ({
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
        fontSize:        '24px',
        width:           '82px',
        height:          '82px',
        lineHeight:      '24px',
        textAlign:       'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color:            '#FFF',
        borderRadius:    '8px',
        boxSizing:       'border-box',
        verticalAlign:   'bottom',
        border:          value.length === index ? '2px solid blue' : '1px solid #ccc'
    });

    return (
        <div onClick={ () => inputRef.current.focus() } style={ { cursor: 'text' } }>
            <input
                ref={ inputRef }
                type="tel"
                name="one-time-code"
                autoFocus
                inputMode="numeric"
                autoComplete="one-time-code"
                value={ value }
                onInput={ handleChange }
                style={ {
                    position: 'absolute',
                    opacity:  0,
                    zIndex:   '-1',
                    width:    '1px',
                    height:   '1px'
                } }
                maxLength="4"
            />
            <div class="onetext-mfa-input">
                {Array.from({ length: 4 }).map((_, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={ `code-${ idx }` } style={ boxStyles(idx) }>
                        {multifactorSuccess ? '✓' : value[idx] || ''}
                    </span>
                ))}
            </div>
        </div>
    );

};

type QuizStepProps = {|
    headerText : string,
    classes : CustomClasses,
    incentiveText : string,
    confirmText : string,
    rejectText : string,
    nextStep : () => void,
    closeModal : () => void
|};

const Quiz = ({
    nextStep,
    classes,
    closeModal,
    headerText,
    incentiveText,
    confirmText,
    rejectText
} : QuizStepProps) : mixed => {

    const { trackCheckpoint } = useTracking();

    const onConfirm = () => {
        nextStep();
        trackCheckpoint(CHECKPOINT.QUIZ_CONFIRMATION);
    };

    return (
        <Fragment>
            <div class="onetext-header-container">
                <div class="subheader">{headerText}</div>
                <div class="header-emphasis">{incentiveText}</div>
            </div>
            <div class="onetext-cta-container">
                <button
                    type='button'
                    class={ `onetext-button-primary ${ classes.primaryButton }` }
                    onClick={ onConfirm }
                >
                    {confirmText}
                </button>
                <button
                    type='button'
                    class={ `onetext-button-secondary ${ classes.secondaryButton }` }
                    onClick={ closeModal  }
                >
                    {rejectText}
                </button>
            </div>
            <div className="onetext-cta-footer" />
        </Fragment>
    );
};

type PhoneInputStepProps = {|
    headerText : string,
    incentiveText ? : string,
    multifactorEmailCaptureHybrid ? : boolean,
    classes : CustomClasses,
    privacyUrl : string,
    termsUrl : string,
    merchantName : string,
    accountToken ? : string,
    setAccountToken : (string) => void,
    setOneTextAccount : ({|
        phone ? : string,
        email ? : string
    |}) => void,
    nextStep : () => void
|};

const PhoneInput = ({
    nextStep,
    merchantName,
    classes,
    termsUrl,
    privacyUrl,
    setAccountToken,
    setOneTextAccount,
    headerText,
    incentiveText,
    multifactorEmailCaptureHybrid,
    accountToken
} : PhoneInputStepProps) : mixed => {

    const { trackCheckpoint } = useTracking();

    const inputRef = useRef(null);

    const [ phone, setPhone ] = useState('');
    const [ twoFactorCode, setTwoFactorCode ] = useState('');

    const [ multifactorMode, setMultifactorMode ] = useState(false);
    const [ multifactorSuccess, setMultifactorSuccess ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ isSubmitting, setIsSubmitting ] = useState(false);

    const [ error, setError ] = useState(null);

    const isFormValid = () => {
        if (multifactorMode) {
            return twoFactorCode.length === 4;
        } else {
            return true;
        }
    };

    const handleEmailSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        setIsSubmitting(true);
        submitOptIn({ email, accountToken }).then(() => {
            setIsSubmitting(false);
            nextStep();
            trackCheckpoint(CHECKPOINT.EMAIL_SUBMISSION_SUCCESS);
        }).catch(() => {
            setIsSubmitting(false);
            setError(`We had a problem saving your email. Please double check to confirm your email is valid and try again.`);
        });
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
    };

    const handleEmailKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleEmailSubmit(event);
        }
    };


    const requestMultifactor = () => {
        setError(null);

        /*
        /* backup to capture mobile autofill that doesnt trigger preact state change
        let phoneCandidate = phone;

        if (!phoneCandidate) {
            phoneCandidate = inputRef.current.value;
        }*/

        submitOptIn({
            phone:                `+1${ phone }`,
            requestTwoFactorCode: true,
            forceTwoFactorCode:   true
        }).catch(() => {
            setError('There was an error saving your phone. Please re-enter to try again.');
            setMultifactorMode(false);
        }).then((result) => {
            // Allow user to proceed to code input while we wait for the response
            // and associate cart in the background
            if (result && result.body) {
                storeAccountLocally(result.body.accountToken);
                if (result.body.email) {
                    setOneTextAccount({ phone, email: result.body.email });
                }
                getCartToken().then((cartToken) => {
                    if (cartToken) {
                        associateAccountToShopifyCart(cartToken);
                    }
                });
            }
            trackCheckpoint(CHECKPOINT.PHONE_SUBMISSION_SUCCESS);
        });
    };

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (!isFormValid()) {
            setError(multifactorMode ? 'Invalid verification code.' : 'Please enter a valid phone number.');
            return;
        }

        setError(null);

        if (multifactorMode) {
            if (multifactorEmailCaptureHybrid) {
                if (multifactorSuccess) {
                    handleEmailSubmit(event);
                }
            } else {
                // Don't display loading animation on hyrbid mode
                setIsSubmitting(true);
            }

            submitOptIn({ twoFactorCode, phone, immediatelyInvokeCoreWelcomeFlow: !multifactorEmailCaptureHybrid }).then((result) => {
                // Wait to set account token and email until AFTER multifactor code is verified
                // to prevent an unnecessary rerender
                setAccountToken(result.body.accountToken);
                setEmail(result.body.email);
                trackCheckpoint(CHECKPOINT.MULTIFACTOR_CODE_SUBMISSION_SUCCESS);
                setIsSubmitting(false);
                setMultifactorSuccess(true);

                if (!multifactorEmailCaptureHybrid) {
                    nextStep();
                }
            }).catch(() => {
                setError('Invalid verification code, please re-enter or try resending your code.');
                setIsSubmitting(false);
            });
        } else {
            setMultifactorMode(true);
            requestMultifactor();
        }
    };

    const onPhoneKeyDown = (event) => {
        if (!event || !event.key) {
            return;
        }

        if (
            event.key.length !== 1 ||
            event.ctrlKey ||
            event.metaKey ||
            event.altKey
        ) {
            return;
        }

        if (event.key.match(/^[0-9]$/)) {
            if (event.currentTarget.value.length < 10) {
                return;
            }
        }

        event.preventDefault();
    };

    const onPhoneKeyUp = (event) => {
        event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]+/g, '').slice(0, 10);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    };

    useEffect(() => {
        if (twoFactorCode.length === 4) {
            handleSubmit();
        }
    }, [ twoFactorCode ]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [ inputRef ]);

    useEffect(() => {
        const input = inputRef.current;

        const handleAnimationStart = (event) => {
            if (event.animationName === 'onAutoFillStart') {
                setPhone(input.value);
                handleSubmit();
            }
        };

        input.addEventListener('animationstart', handleAnimationStart);

        return () => {
            input.removeEventListener('animationstart', handleAnimationStart);
        };
    }, []);

    return (
        <div class="onetext-modal-container">
            <div class="onetext-header-container">
                {multifactorMode ? (
                    <div>
                        <div class='subheader2'>Finish signing up</div>
                        <div class='subheader3'>
                            { `Enter your sms code ${ multifactorEmailCaptureHybrid
                                ? 'and email '
                                : '' }${ incentiveText
                                ? `for ${ incentiveText }`
                                : 'to unlock your offer' }`}
                        </div>
                    </div>
                ) : (
                    <div>
                        <div class='subheader2'>{incentiveText ? `Take ${ incentiveText }` : ''}</div>
                        <div class='subheader3'>
                            {headerText}
                        </div>
                    </div>
                )}
            </div>
            <div class='onetext-modal-error-container'>
                {error && <div class='onetext-modal-error'>{error}</div>}
            </div>
            <div class="onetext-cta-container">
                {multifactorMode ? (
                    <Fragment>
                        <MultifactorCodeInput value={ twoFactorCode } setValue={ setTwoFactorCode } multifactorSuccess={ multifactorSuccess } />
                        <div className='onetext-mfa-controls text-xs'>
                            <div />
                            {multifactorSuccess ? (
                                <div
                                    style={ { textAlign: 'right', color: '#FFF' } }
                                >
                                    Verified!
                                </div>

                            ) : (
                                <div
                                    style={ { textAlign: 'right' } }
                                    onClick={ () => requestMultifactor() }
                                >
                                    <a href="#" style={ { color: '#FFF' } }>
                                        Resend code
                                    </a>
                                </div>)}
                        </div>
                        {multifactorEmailCaptureHybrid ? (
                            <div class='field email-field'>
                                <input
                                    ref={ inputRef }
                                    type="email"
                                    name="email"
                                    id="email"
                                    tabIndex="0"
                                    autocomplete="email"
                                    placeholder="Enter your email"
                                    value={ email }
                                    onChange={ handleEmailChange }
                                    onKeyPress={ handleEmailKeyPress }
                                    required
                                />
                            </div>
                        ) : null}
                    </Fragment>
                ) : (
                    <div class='field phone-field'>
                        <div class='prefix'>
                            +1
                        </div>
                        <input
                            ref={ inputRef }
                            type="tel" name="phone" id="phone" autocomplete="tel"
                            placeholder="Phone Number"
                            pattern='[0-9]+'
                            required
                            onKeyDown={ onPhoneKeyDown }
                            onKeyUp={ onPhoneKeyUp }
                            onChange={ event => setPhone(event.currentTarget.value) }
                            onInput={ event => setPhone(event.currentTarget.value) }
                            onKeyPress={ handleKeyPress }
                        />
                    </div>
                )}
            </div>
            <div class="onetext-cta-container">
                <button
                    type='submit'
                    class={ `onetext-button-primary ${ classes.primaryButton }` }
                    onClick={ handleSubmit }
                    disabled={ isSubmitting }
                >
                    {isSubmitting ?  <LoadingDots /> : multifactorMode && incentiveText ? `Unlock ${ incentiveText }` : 'Continue'}
                </button>
                {multifactorMode ? null
                    : (
                        <div class='terms'>
                            {`By entering your phone number on this form, you agree to receive regular, personalized marketing offers, updates and cart reminders from ${ merchantName } and OneText by text at the number provided. Reply STOP to cancel. Msg and data rates may apply. View Terms & Privacy.`}<a href={ privacyUrl } target="_blank">Privacy Policy</a>{' & '}<a href={ termsUrl } target="_blank">Terms.</a>.
                        </div>
                    )}
            </div>
        </div>
    );
};


type EmailInputStepProps = {|
    headerText : string,
    privacyUrl : string,
    termsUrl : string,
    merchantName : string,
    classes : CustomClasses,
    accountToken : string,
    emailDefault ? : string,
    nextStep : () => void
|};

const EmailInput = ({
    nextStep,
    merchantName,
    termsUrl,
    privacyUrl,
    headerText,
    accountToken,
    emailDefault = '',
    classes
} : EmailInputStepProps) : mixed => {

    const { trackCheckpoint } = useTracking();

    const inputRef = useRef(null);

    const [ email, setEmail ] = useState(emailDefault ?? '');
    // const [ isEmailValid, setIsEmailValid ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);


    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        // setIsEmailValid((/^.+@.+\..+$/).test(inputEmail));
    };


    const onClickSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        // currently working on API endpoint to consent email for marketing
        // and propagate to klaviyo. Given second nature to phone, use account token directly to associate
        submitOptIn({ email, accountToken }).then(() => {
            setIsSubmitting(false);
            nextStep();
            trackCheckpoint(CHECKPOINT.EMAIL_SUBMISSION_SUCCESS);
        }).catch(() => {
            setIsSubmitting(false);
            nextStep();
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onClickSubmit(event);
        }
    };

    useEffect(() => {
        if (emailDefault.length > 0) {
            setEmail(emailDefault);
            // setIsEmailValid((/^.+@.+\..+$/).test(emailDefault));
        }

    }, [ emailDefault ]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

    }, [ inputRef ]);

    useEffect(() => {
        const input = inputRef.current;

        const handleAnimationStart = (event) => {
            if (event.animationName === 'onAutoFillStart') {
                const inputEmail = input.value;
                submitOptIn({ email: inputEmail, accountToken }).then(() => {
                    nextStep();
                });
            }
        };

        input.addEventListener('animationstart', handleAnimationStart);

        return () => {
            input.removeEventListener('animationstart', handleAnimationStart);
        };
    }, []);
    
    return (
        <div class="onetext-modal-container">
            <div class='onetext-header-container'>
                <div class='subheader2'>
                    {headerText}
                </div>
            </div>
            <div class="onetext-cta-container">
                <div class='field email-field'>
                    <input
                        ref={ inputRef }
                        type="email"
                        name="email"
                        id="email"
                        tabIndex="0"
                        autocomplete="email"
                        placeholder="Enter your email"
                        value={ email }
                        onChange={ handleEmailChange }
                        onKeyPress={ handleKeyPress }
                        required
                    />
                </div>
            </div>
            <div class="onetext-cta-container">
                <button
                    tabIndex="1"
                    type='submit'
                    class={ `onetext-button-primary ${ classes.primaryButton }` }
                    onClick={ onClickSubmit }
                >
                    {isSubmitting ?  <LoadingDots /> : 'Continue'}
                </button>
                <div class='terms'>
                    {`By submitting this form and signing up for emails, you consent to receive marketing text messages ${ merchantName }. `}<a href={ privacyUrl } target="_blank">Privacy Policy</a>{' & '}<a href={ termsUrl } target="_blank">Terms.</a>.
                </div>
            </div>
        </div>
    );
};

type SuccessStepProps = {|
    headerText : string,
    onCompleteOptIn : () => void
|};

const Success = ({ headerText, onCompleteOptIn } : SuccessStepProps) : mixed => {

    const { trackCheckpoint } = useTracking();

    useEffect(() => {
        trackCheckpoint(CHECKPOINT.SUCCESS_PAGE_RENDERED);
        // Automtically close the modal after 3 seconds
        setTimeout(() => {
            onCompleteOptIn();
        }, 3000);
    }, []);

    return (
        <div class="onetext-header-container">
            <div class='success'>
                { headerText }
            </div>
        </div>
    );
};

type OptInModalProps = {|
  configuration : BrandModalConfiguration
|};

const OptInModal = ({
    configuration
} : OptInModalProps) : mixed => {

    const [ stepIdx, setStepIdx ] = useState(0);
    const [ visible, setVisible ] = useState(true);
    const [ accountToken, setAccountToken ] = useState();
    const [ oneTextAccount, setOneTextAccount ] = useState({ phone: '', email: '' });

    const clickOverlay = () => {
        setVisible(false);
    };

    const clickExit = () => {
        setVisible(false);
    };

    const onCompleteOptIn = () => {
        setVisible(false);
    };

    const clickModal = (event) => {
        event.stopPropagation();
    };

    const {
        steps,
        merchantName,
        theme, customClasses,
        trackingConfig,
        urls: {
            termsUrl, privacyUrl
        } } = configuration;

    const stepConfig = steps[stepIdx];

    const nextStep = () => {
        if (stepIdx === steps.length - 1) {
            setVisible(false);
        } else {
            setStepIdx(stepIdx + 1);
        }
    };

    const previousStep = () => {
        if (stepIdx === 0) {
            setVisible(false);
        } else {
            setStepIdx(stepIdx - 1);
        }
    };

    useEffect(() => {
        if (document.body !== null) {
            if (visible) {
                // $FlowFixMe
                document.body.classList.add('background-no-scroll');
                // $FlowFixMe
                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                // $FlowFixMe
                const body : HTMLBodyElement = document.body;
                body.style.position = 'fixed';
                body.style.top = `-${ scrollY }`;
            } else {
                // $FlowFixMe
                document.body.classList.remove('background-no-scroll');
                // $FlowFixMe
                const body : HTMLBodyElement = document.body;
                const scrollY = body.style.top;
                body.style.position = '';
                body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            }

            return () => {
                // $FlowFixMe
                document.body.classList.remove('background-no-scroll');
                // $FlowFixMe
                const body : HTMLBodyElement = document.body;
                const scrollY = body.style.top;
                body.style.position = '';
                body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            
            };
        }
    }, [ visible ]);

    if (!visible) {
        return null;
    }

    useEffect(() => {
        fireCheckpointEvent(CHECKPOINT.POPUP_RENDERED, trackingConfig);
    }, []);

    const stepComponents = {
        [OPT_IN_STEP.QUIZ]: (
            <Quiz
                nextStep={ nextStep }
                closeModal={ clickExit }
                classes={ customClasses }
                { ...stepConfig }
            />
        ),
        [OPT_IN_STEP.PHONE_INPUT]: (
            <PhoneInput
                nextStep={ nextStep }
                merchantName={ merchantName }
                termsUrl={ termsUrl }
                accountToken={ accountToken }
                setAccountToken={ setAccountToken }
                setOneTextAccount={ setOneTextAccount }
                privacyUrl={ privacyUrl }
                classes={ customClasses }
                { ...stepConfig }
            />
        ),
        [OPT_IN_STEP.EMAIL_INPUT]: (
            <EmailInput
                nextStep={ nextStep }
                previousStep={ previousStep }
                merchantName={ merchantName }
                emailDefault={ oneTextAccount.email }
                termsUrl={ termsUrl }
                accountToken={ accountToken }
                privacyUrl={ privacyUrl }
                classes={ customClasses }
                { ...stepConfig }
            />
        ),
        [OPT_IN_STEP.SUCCESS]: (
            <Success { ...stepConfig } onCompleteOptIn={ onCompleteOptIn } />
        )
    };

    return (
        <TrackingProvider trackingConfig={ trackingConfig }>
            <style>{ OPT_IN_MODAL_STYLE(theme) }</style>
            <div class='onetext-opt-in-modal-overlay' onClick={ clickOverlay }>
                <div class='onetext-opt-in-modal' onClick={ clickModal }>

                    <div class="onetext-close-button" onClick={ clickExit }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21.242 21.242"><g stroke="#FFF" stroke-width="2" transform="translate(-0.579 -0.579)"><line x2="17" y2="17" transform="translate(2.66 2.66)" fill="none" stroke="#FFF" stroke-width="2" /><line x1="17" y2="17" transform="translate(2.66 2.66)" fill="none" stroke="#FFF" stroke-width="2" /></g></svg>
                    </div>
                    <h1 className="onetext-theme-logo">
                        <img width="100px" src={ theme.logo } />
                    </h1>
                    {stepComponents[stepConfig.step] || null}
                </div>
            </div>
        </TrackingProvider>
    );
};

export const ConsentPopup = (configuration : BrandModalConfiguration) : {|
  render : (container? : string | HTMLElement) => Promise<void>
|} => {

    return {
        render (container : string | HTMLElement = 'body') : Promise<void> {
            return promiseTry(() => {
                const hasAccount = Boolean(getAccountToken());

                if (hasAccount) {
                    return;
                }

                const localStorageEnabled = isLocalStorageEnabled();
                const hasModalShown = localStorageEnabled ? localStorage.getItem(LOCALSTORAGE_KEY.ONETEXT_MODAL) : undefined;

                if (hasModalShown) {
                    return;
                } else {
                    if (localStorageEnabled) {
                        localStorage.setItem(LOCALSTORAGE_KEY.ONETEXT_MODAL, 'true');
                    }
                }

                renderComponent({
                    component:  OptInModal,
                    props:      {
                        configuration
                    },
                    shadowDOM:  false,
                    container,
                    newElement: true
                });

            });
        }
    };
};

