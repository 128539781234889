/* @flow */

// eslint-disable-next-line import/no-commonjs
const int = require('./interface');
// eslint-disable-next-line import/no-commonjs
const init = require('./initialize');

const ex : {
    [key : string] : mixed
} = {};

for (const [ key, value ] of Object.entries(int)) {
    if (value && typeof value.__get__ === 'function') {
        Object.defineProperty(ex, key, {
            get: () => {
                // $FlowFixMe
                return value.__get__();
            }
        });
    } else {
        ex[key] = value;
    }
}

// eslint-disable-next-line import/no-commonjs
module.exports = ex;

init.init();
