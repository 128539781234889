/* @flow */

import { callOneTextAPI, type CallOneTextAPIResult } from './api';

type SendShopifyBrowseEventOptions = {|
    accountToken : string
|};

type SendShopifyBrowseEventResponse = {|
    
|};

export const sendShopifyBrowseEvent = ({
    accountToken
} : SendShopifyBrowseEventOptions) : Promise<CallOneTextAPIResult<SendShopifyBrowseEventResponse>> => {
    return callOneTextAPI({
        path: 'shopify/webhook/client/browse',
        body: {
            accountToken
        }
    });
};
