/* @flow */

import { sendShopifyBrowseEvent, sendShopifyProductView } from '../api';
import { userIdentifiedEvent } from '../event';
import { getShopifyPageData, isShopifyStore, SHOPIFY_PAGE_TYPE } from '../partner';

type TrackRecoveryOptions = {|
    accountToken : string
|};

let sentProductRecovery = false;
let sentBrowseRecovery = false;

export const trackRecovery = ({ accountToken } : TrackRecoveryOptions) : void => {
    if (isShopifyStore()) {
        getShopifyPageData().then((pageData) => {
            const isEligibleProductPage = pageData?.pageType === SHOPIFY_PAGE_TYPE.PRODUCT
            || pageData?.pageType === SHOPIFY_PAGE_TYPE.PAGE;

            const canSendBrowseRecovery = !sentBrowseRecovery && !sentProductRecovery;

            if (isEligibleProductPage && pageData?.productID && !sentProductRecovery) {
                sendShopifyProductView({
                    accountToken,
                    productID:   pageData.productID
                }).then(() => {
                    sentProductRecovery = true;
                });
            } else if (Math.random() < 0.2 && canSendBrowseRecovery) {
                sendShopifyBrowseEvent({
                    accountToken
                }).then(() => {
                    sentBrowseRecovery = true;
                });
            }
        });
    }
};

export const subscribeTrackingListeners = () => {
    userIdentifiedEvent.on(trackRecovery);
};
