/* @flow */
import type { OptInModalTheme } from './types';

export const OPT_IN_MODAL_STYLE = ({
    backgroundImage,
    backgroundImageMobile,
    primaryColor,
    primaryColorAccent
} : OptInModalTheme) : string => ` .onetext-opt-in-modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100vw;
     height: 100vh;
     background: rgba(0, 0, 0, 0.5);
     cursor: pointer;
     animation: fade-in 0.3s linear forwards;
     opacity: 0;
     z-index: 2147483647;
     display: flex;
     justify-content: center;
     align-items: center;
}
 .onetext-opt-in-modal {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 100%;
     height: 100%;
     padding: 20px;
     overflow-y: hidden;
     cursor: auto;
     border-radius: 4px;
     animation: pop-in 0.1s ease-in-out forwards;
     text-align: center;
     font-family: Helvetica, Arial, sans-serif;
     font-size: 16px;
     background-image: url(${
    backgroundImageMobile
}
    );
     background-size: cover;
     background-position: center;
}
 @media (min-width: 600px) {
     .onetext-opt-in-modal {
     background-image: url(${
    backgroundImage
}
    );
         width: 600px;
         height: 600px;
         padding: 60px;
    }
}
  
.onetext-mfa-input {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.onetext-mfa-controls {
    display: flex;
    color: #FFF;
    justify-content: space-between;
    margin-top: -12px;
}

.onetext-theme-logo {
    height: 60px;
}

 .onetext-close-button {
     border-color: transparent;
     outline: red;
     margin: 0;
     border-style: none;
     border-image: unset;
     border-image-source: none;
     border-image-slice: unset;
     border-image-repeat: unset;
     border-image-outset: unset;
     color: #FFF;
     padding-top: 7px;
     padding-bottom: 7px;
     padding-left: 7px;
     padding-right: 7px;
     position: fixed;
     top: 10px;
     width: 30px;
     height: 30px;
     stroke-linecap: round;
     stroke-width: unset;
     animation-duration: 0.3s;
     animation-delay: 0.8s;
     right: 10px;
     -webkit-animation-name: fadeIn_smallDesktop_slmx5tE2S6;
     transition-duration: 0s;
     transition-delay: 0s;
     transform: none;
     animation-fill-mode: both;
     left: auto;
     bottom: auto;
     stroke: unset;
     border-width: 0px;
     background-color: transparent;
     box-sizing: border-box;
     z-index: 2;
     cursor: pointer;
     user-select: none;
     line-height: 0;
     animation-play-state: running;
}
 .onetext-header-container {
     #color: #1f283a;
     color: #10151e;
     -webkit-font-smoothing: antialiased;
     -webkit-text-size-adjust: 100%;
     --header-wrapper-height: 66px;
     text-align: center;
     box-sizing: border-box;
     font-family: var(---font-stack-heading);
     font-weight: var(---font-weight-heading);
     font-style: var(---font-style-heading);
     text-transform: var(---font-heading-caps);
     letter-spacing: var(---font-heading-space);
     line-height: 1.2;
     font-size: 2.57143em;
     margin-top: 40px;
}
 .onetext-opt-in-modal h1 {
     font-size: 18px;
     margin: 0;
     margin-bottom: 5px;
}
 .onetext-header-container {
    color: #FFF;
}
 .onetext-header-container .header-emphasis {
     font-size: 1.9em;
     font-weight: 800;
     color: #FFF;
     text-shadow: 1px 1px 2px pink;
}
 .onetext-header-container .subheader {
     text-transform: uppercase;
     font-weight: 600;
}
 .onetext-header-container .subheader2 {
     font-weight: 600;
     text-transform: uppercase;
     font-size: 0.8em;
}
 .onetext-header-container .subheader3 {
    font-weight: 500;
    font-size: 0.44em;
    text-transform: none;
    margin-top: 8px;
}
 .onetext-header-container .success {
     font-size: 1.4em;
     font-weight: 800;
     color: #FFF;
     text-shadow: 1px 1px 2px pink;
     display: flex;
     padding-top: 48px;
     flex-direction: column;
     justify-content: center;
     align-items: center;
}
 @keyframes fade-in {
     0% {
         opacity: 0;
    }
     100% {
         opacity: 1;
    }
}
 @keyframes pop-in {
     0% {
         transform: translate(-50%, -50%) scale(0.1);
    }
     100% {
         transform: translate(-50%, -50%) scale(1);
    }
}
 .onetext-cta-container {
     display: flex;
     flex-direction: column;
     gap: 24px;
     margin-top: 16px;
}
 .onetext-modal-error-container {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     font-weight: 700;
     padding: 4px;
     color: #FFF;
}
 .onetext-modal-error {
     position: absolute;
     top: 0px;
     max-width: 30rem;
    padding: 7px;
     background: ${ primaryColor };
     color: ${ primaryColorAccent };
}
 .onetext-success {
     display: flex;
     height: 100%;
     justify-content: center;
     align-items: center;
}
 .onetext-cta-footer {
     margin-top: 16px;
     padding-bottom: 18px;
}
 .onetext-button {
     width: 100%;
     padding: 6px 30px;
     margin: 0;
     text-decoration: none;
     text-transform: uppercase;
     text-align: center;
     vertical-align: middle;
     white-space: nowrap;
     cursor: pointer;
     border: 1px solid transparent;
     font-weight: 700;
     border-radius: 50px;
}
 .onetext-button-primary {
     font-size: 2em !important;
     padding-top: 8px;
     padding-bottom: 8px;
     background-color: ${
    primaryColor
}
    ;
     color: ${
    primaryColorAccent
}
    ;
}
 .onetext-button-secondary {
     background-color: white;
     color: #000;
}
 .onetext-modal-container .field {
     width: 100%;
     border: 1px solid #ccc;
     border-radius: 4px;
     font-size: 16px;
     line-height: 16px;
     overflow: hidden;
     display: flex;
     align-items: center;
     justify-content: center;
     background: white;
}
 .onetext-modal-container .phone-field .prefix {
     color: #aaa;
     font-size: 30px;
     line-height: 30px;
     padding-left: 8px;
     padding-right: 8px;
}
 .onetext-modal-container .p
one-field input:focus {
    box-shadow: none;
     outline: none;
}

 .onetext-modal-container .field input {
     border: none;
     font-size: 1.9em;
     height: 64px;
     border-radius: 4px;
     background: white;
     width: 100%;
     margin: 0;
}
 .onetext-modal-container .terms {
     font-size: 10px;
     color: #FFF;
     text-align: center;
     margin-top: 10px;
     background-color: rgba(0, 0, 0, 0.6);
     padding: 8px;
     border-radius: 4px;
}
 .onetext-modal-container .terms a {
     color: #FFF;
     text-decoration: underline;
}
 .onetext-modal-container .submit-phone-button {
     margin-top: 6px;
     width: 100%;
}
 .onetext-modal-container .submit-phone-button button {
     width: 100%;
     background: #333;
     color: white;
     border: none;
     border-radius: 4px;
     height: 30px;
     cursor: pointer;
     .onetext-modal-container .submit-phone-button button:disabled {
         background: #ccc;
         color: #eee;
         cursor: default;
    }
     .onetext-modal-container .email-field input {
         border: none;
         font-size: 1.9em;
         height: 64px;
         border-radius: 4px;
         background: white;
         width: 100%;
         margin: 0;
    }
     .onetext-modal-container .email-field input:focus {
         outline: none;
    }
     .onetext-modal-container .success {
         margin-top: 10px;
         font-size: 12px;
         color: #333;
         text-align: center;
    }

    @keyframes onAutoFillStart { from {} to {} }

    input:-webkit-autofill {
        animation-name: onAutoFillStart;
        animation-duration: 1s;
    }

    .background-no-scroll {
        overflow: hidden;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
    }

  `;
     
