/* @flow */

import {
    associateShopperAndStoreLocally, generateCoreFlowLink, generateCustomFlowLink, getBasicSwitchToSMSThreadLink,
    getSwitchToSMSThreadLink, sendCoreFlowStartMessage, sendCustomFlowStartMessage, startCoreFlow, startCustomFlow,
    switchToSMSThread
} from '../api';
import { getCartToken } from '../cart';
import { renderComponent } from '../component';
import { LinkModal } from '../components';
import { isDevice, noop, promiseTry } from '../lib';

type LaunchFlowOptions = {|
    externalID ? : string,
    coreFlowType ? : string,
    phoneNumber? : string,
    email? : string,
    marketingConsentGranted? : boolean,
    incomingMessageText? : string,
    parameters ? : {
        [ key : string ] : mixed
    },
    allowAutoThreadSwitch ? : boolean,
    allowModal ? : boolean,
    followQuietHours ? : boolean
|};

const launchFlow = ({
    phoneNumber,
    email,
    marketingConsentGranted,
    coreFlowType,
    externalID,
    parameters = {},
    incomingMessageText,
    allowAutoThreadSwitch = false,
    allowModal = false,
    followQuietHours = true
} : LaunchFlowOptions) : Promise<void> => {
    if (!coreFlowType && !externalID) {
        throw new Error(`Expected coreFlowType or externalID`);
    }

    const isMobile = isDevice();

    const container = 'body';
    const title = `Continue with a text`;
    const subTitleSwitchMobile = `Head over to your phone's messaging or sms app to continue`;
    const subTitleSwitchDesktop = `Head over to your phone's messaging or sms app to continue, or scan:`;
    const buttonText = `Take me there`;
    const showButton = isMobile;
    const showQR = !isMobile;
    const forceSMSRedirect = isMobile;

    const sendFlowToPhone = () => {
        if (coreFlowType) {
            return startCoreFlow({
                body: {
                    receivers: {
                        type:     'contacts',
                        contacts: [
                            {
                                phoneNumber,
                                email
                            }
                        ]
                    },
                    coreFlowType,
                    customFlowParameters: parameters,
                    allowMultipleStarts:  true,
                    followQuietHours,
                    marketingConsentGranted
                }
            });
        } else if (externalID) {
            return startCustomFlow({
                body: {
                    receivers: {
                        type:     'contacts',
                        contacts: [
                            {
                                phoneNumber,
                                email
                            }
                        ]
                    },
                    externalID,
                    parameters,
                    allowMultipleStarts: true,
                    followQuietHours,
                    marketingConsentGranted
                }
            });
        } else {
            throw new Error(`Expected coreFlowType or externalID`);
        }
    };

    const launchFlowStartMessage = () => {
        if (coreFlowType) {
            return sendCoreFlowStartMessage({
                message:              incomingMessageText,
                coreFlowType,
                customFlowParameters: parameters,
                forceSMSRedirect
            });
        } else if (externalID) {
            return sendCustomFlowStartMessage({
                message:          incomingMessageText,
                externalID,
                parameters,
                forceSMSRedirect
            });
        } else {
            throw new Error(`Expected coreFlowType or externalID`);
        }
    };

    const associateShopper = () => {
        if (phoneNumber) {
            return getCartToken().then(cartToken => {
                if (cartToken) {
                    associateShopperAndStoreLocally({ phone: phoneNumber, cartToken });
                }
            }).catch(noop);
        }
    };

    const createFlowStartMessageURL = () => {
        if (coreFlowType) {
            return generateCoreFlowLink({
                message:              incomingMessageText,
                coreFlowType,
                customFlowParameters: parameters,
                forceSMSRedirect,
                raw:                  !showQR
            });
        } else if (externalID) {
            return generateCustomFlowLink({
                message: incomingMessageText,
                externalID,
                parameters,
                forceSMSRedirect,
                raw:     !showQR
            });
        } else {
            throw new Error(`Expected coreFlowType or externalID`);
        }
    };

    type RenderModalOptions = {|
        url : () => (Promise<string> | string)
    |};

    const renderModal = ({ url } : RenderModalOptions) => {
        renderComponent({
            component: LinkModal,
            props:     {
                url,
                title,
                subTitle: isMobile
                    ? subTitleSwitchMobile
                    : subTitleSwitchDesktop,
                showButton,
                buttonText,
                showQR
            },
            container,
            shadowDOM:  false,
            newElement: true
        });
    };

    const renderFlowStartModal = () => {
        renderModal({
            url: createFlowStartMessageURL
        });
    };

    const renderThreadSwitchModal = () => {
        renderModal({
            url: () => {
                if (showQR) {
                    return getSwitchToSMSThreadLink().then(({ body }) => {
                        return body.link;
                    });
                } else {
                    return getBasicSwitchToSMSThreadLink();
                }
            }
        });
    };

    return promiseTry(() => {
        if (phoneNumber) {
            return Promise.all([
                sendFlowToPhone(),
                associateShopper(),

                allowAutoThreadSwitch && isMobile
                    ? switchToSMSThread()
                    : undefined,

                allowModal
                    ? renderThreadSwitchModal()
                    : undefined
            ]);
        } else if (isMobile) {
            if (!allowAutoThreadSwitch && !allowModal) {
                throw new Error(`Can not start flow without auto thread switch or modal`);
            }

            return Promise.all([
                allowAutoThreadSwitch
                    ? launchFlowStartMessage()
                    : undefined,

                allowModal
                    ? renderFlowStartModal()
                    : undefined
            ]);
        } else {
            if (!allowModal) {
                throw new Error(`Can not start flow without modal`);
            }

            return allowModal
                ? renderFlowStartModal()
                : undefined;
        }
    }).then(noop);
};

type LaunchCustomFlowOptions = {|
    externalID : string,
    phoneNumber? : string,
    email? : string,
    incomingMessageText? : string,
    parameters ? : {
        [ key : string ] : mixed
    },
    allowAutoThreadSwitch ? : boolean,
    allowModal ? : boolean,
    followQuietHours ? : boolean,
    marketingConsentGranted ? : boolean
|};

const launchCustomFlow = ({
    externalID,
    phoneNumber,
    email,
    incomingMessageText,
    parameters,
    allowAutoThreadSwitch,
    allowModal,
    followQuietHours = true,
    marketingConsentGranted
} : LaunchCustomFlowOptions) : Promise<void> => {
    if (!externalID) {
        throw new Error(`Expected externalID`);
    }

    return launchFlow({
        externalID,
        phoneNumber,
        email,
        incomingMessageText,
        parameters,
        allowAutoThreadSwitch,
        allowModal,
        followQuietHours,
        marketingConsentGranted
    });
};

type LaunchCoreFlowOptions = {|
    type : string,
    phoneNumber? : string,
    email? : string,
    incomingMessageText? : string,
    customFlowParameters ? : {
        [ key : string ] : mixed
    },
    allowAutoThreadSwitch ? : boolean,
    allowModal ? : boolean,
    followQuietHours ? : boolean,
    marketingConsentGranted ? : boolean
|};

const launchCoreFlow = ({
    type,
    phoneNumber,
    email,
    incomingMessageText,
    customFlowParameters,
    allowAutoThreadSwitch,
    allowModal,
    followQuietHours = true,
    marketingConsentGranted
} : LaunchCoreFlowOptions) : Promise<void> => {
    if (!type) {
        throw new Error(`Expected flow type`);
    }

    return launchFlow({
        coreFlowType: type,
        phoneNumber,
        email,
        incomingMessageText,
        parameters:   customFlowParameters,
        allowAutoThreadSwitch,
        allowModal,
        followQuietHours,
        marketingConsentGranted
    });
};

export const flow = {
    launch: launchCustomFlow,
    launchCustomFlow,
    launchCoreFlow
};

export const custom = {
    start: launchCustomFlow
};

const coreFlowTypes = {
    welcome: 'welcome'
};

export const core = {
    types: coreFlowTypes,
    start: launchCoreFlow
};
