/* @flow */

export const SDK_ATTRIBUTE = {
    ENV:           ('data-env' : 'data-env'),
    ACCOUNT_TOKEN: ('data-account-token' : 'data-account-token'),
    CLIENT_ID:     ('data-client-id' : 'data-client-id')
};

export const SDK_QUERY = {
    ACCOUNT_TOKEN: ('onetext-account-token' : 'onetext-account-token'),
    CLIENT_ID:     ('onetext-client' : 'onetext-client')
};

export const ENV = {
    DEMO:       ('demo' : 'demo'),
    TEST:       ('test' : 'test'),
    LOCAL:      ('local' : 'local'),
    SANDBOX:    ('sandbox' : 'sandbox'),
    PRODUCTION: ('production' : 'production')
};

export const TOKEN = {
    ACCOUNT: ('onetext_account' : 'onetext_account'),
    CLIENT:  ('onetext_client' : 'onetext_client')
};

export const QUERY_PARAM = {
    ONETEXT_ACCOUNT_TOKEN_LEGACY: ('onetext-token' : 'onetext-token'),
    ONETEXT_ACCOUNT_TOKEN:        ('onetext-account-token' : 'onetext-account-token'),
    KLAVIYO_IDENTIFIER:           ('nb_klid' : 'nb_klid')
};

export const LOCALSTORAGE_KEY = {
    ONETEXT_ACCOUNT_TOKEN: ('onetext-account-token' : 'onetext-account-token'),
    ONETEXT_MODAL:         ('onetext-modal' : 'onetext-modal')
};
