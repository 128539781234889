/* @flow */

import { userIdentifiedEvent } from '../event';
import { storeAccountLocally } from '../lib';

import { callOneTextAPI, type CallOneTextAPIResult } from './api';

type AssociateShopperOptions = {|
    accountToken ? : string,
    cartToken ? : string,
    klaviyoID ? : string,
    phone ? : string
|};

type AssociateShopperResponse = {|
    accountToken : string
|};

export const associateShopper = ({
    accountToken,
    cartToken,
    klaviyoID,
    phone
} : AssociateShopperOptions) : Promise<CallOneTextAPIResult<AssociateShopperResponse>> => {
    return callOneTextAPI({
        path: 'associate',
        body: {
            accountToken,
            cartToken,
            klaviyoID,
            phone
        }
    });
};

export const associateShopperAndStoreLocally = (payload : AssociateShopperOptions) : Promise<string> => {
    return associateShopper(payload).then(({ body }) => {
        const { accountToken } = body;
        storeAccountLocally(accountToken);
        userIdentifiedEvent.emit({ accountToken });
        return accountToken;
    });
};

type AddShopperPropertiesOptions = {|
    phone : string,
    properties : {
        [ key : string ] : mixed
    }
|};

type AddShopperPropertiesResponse = {|
    
|};

export const addShopperProperties = ({
    phone,
    properties
} : AddShopperPropertiesOptions) : Promise<CallOneTextAPIResult<AddShopperPropertiesResponse>> => {
    return callOneTextAPI({
        path: 'customer/properties',
        body: {
            phone,
            properties
        }
    });
};
