
/* @flow */

import { getAccountToken, promiseTry } from '../lib';

type Customer = {|
    recognized : boolean
|};

export const get = () : Promise<Customer> => {
    return promiseTry(() => {
        const recognized = getAccountToken()
            ? true
            : false;

        return {
            recognized
        };
    });
};
