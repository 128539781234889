/* @flow */
import { noop } from '../lib';

import { eventEmitter, type EventEmitter } from './event';

type UserEvent = {|
    accountToken : string
|};

export const userIdentifiedEvent : EventEmitter<$ReadOnlyArray<UserEvent>> = eventEmitter < $ReadOnlyArray<UserEvent>>({
    onError: noop
});
