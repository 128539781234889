/* @flow */

import { getSDKAccountToken, getSDKClientID, isDevice, promiseTry } from '../../lib';
import { callOneTextAPI, type CallOneTextAPIResult, constructOneTextURL, redirectInPopup } from '../api';

type StartCoreFlowOptions = {|
    body : mixed
|};

type StartCoreFlowResponse = {|
    
|};

export const startCoreFlow = ({
    body
} : StartCoreFlowOptions) : Promise<CallOneTextAPIResult<StartCoreFlowResponse>> => {
    return callOneTextAPI({
        path: 'flow/core/start',
        body
    });
};

type GetCoreFlowLinkOptions = {|
    body : mixed
|};

type GetCoreFlowLinkResponse = {|
    link : string,
    threadLink : string
|};

export const getCoreFlowLink = ({
    body
} : GetCoreFlowLinkOptions) : Promise<CallOneTextAPIResult<GetCoreFlowLinkResponse>> => {
    return callOneTextAPI({
        path: 'flow/core/link',
        body
    });
};
type GetRawCoreFlowLinkOptions = {|
    message? : string,
    email? : string,
    coreFlowType : string,
    customFlowParameters? : {
        [ key : string ] : mixed
    },
    forceSMSRedirect? : boolean
|};

export const getRawCoreFlowLink = ({
    coreFlowType,
    message,
    customFlowParameters,
    forceSMSRedirect = false
} : GetRawCoreFlowLinkOptions) : string => {
    const accountToken = getSDKAccountToken();
    const clientID = getSDKClientID();

    if (!accountToken && !clientID) {
        throw new Error(`Can not determine clientID`);
    }

    const query : {
        [ key : string ] : string
    } = {
        coreFlowType,
        domain: window.location.origin
    };

    if (customFlowParameters) {
        query.parameters = JSON.stringify(customFlowParameters);
    }

    if (message) {
        query.message = message;
    }

    if (accountToken) {
        query.accountToken = accountToken;
    }

    if (clientID) {
        query.clientID = clientID;
    }

    if (forceSMSRedirect) {
        query.forceSMSRedirect = 'true';
    }

    return constructOneTextURL({
        path: 'flow/start',
        query
    });
};

type GenerateCoreFlowLinkOptions = {|
    message? : string,
    email? : string,
    coreFlowType : string,
    customFlowParameters? : {
        [ key : string ] : mixed
    },
    forceSMSRedirect? : boolean,
    raw ? : boolean
|};

export const generateCoreFlowLink = ({
    message,
    email,
    coreFlowType,
    customFlowParameters,
    forceSMSRedirect,
    raw
} : GenerateCoreFlowLinkOptions) : Promise<string> => {
    return promiseTry(() => {
        return raw
            ? getRawCoreFlowLink({
                message,
                email,
                coreFlowType,
                customFlowParameters,
                forceSMSRedirect
            })
            : getCoreFlowLink({
                body: {
                    message,
                    coreFlowType,
                    customFlowParameters,
                    forceSMSRedirect
                }
            }).then(({ body }) => {
                return body.link;
            });
    });
};

type SendCoreFlowStartMessageOptions = {|
    message? : string,
    email? : string,
    coreFlowType : string,
    customFlowParameters? : {
        [ key : string ] : mixed
    },
    forceSMSRedirect? : boolean
|};

export const sendCoreFlowStartMessage = ({
    coreFlowType,
    message,
    customFlowParameters,
    forceSMSRedirect = false
} : SendCoreFlowStartMessageOptions) : Promise<void> => {
    return promiseTry(() => {
        if (isDevice()) {
            return redirectInPopup({
                url: getRawCoreFlowLink({
                    coreFlowType,
                    message,
                    customFlowParameters,
                    forceSMSRedirect
                })
            });
        }
    });
};
