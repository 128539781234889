/* @flow */

import { callOneTextAPI, type CallOneTextAPIResult } from './api';

type SendShopifyProductViewOptions = {|
    accountToken : string,
    productID : string,
    variantID ? : string,
    quantity ? : number,
    variantName ? : string
|};

type SendProductViewResponse = {|
    
|};

export const sendShopifyProductView = ({
    accountToken,
    productID,
    variantID,
    quantity,
    variantName
} : SendShopifyProductViewOptions) : Promise<CallOneTextAPIResult<SendProductViewResponse>> => {
    return callOneTextAPI({
        path: 'shopify/webhook/client/product/view',
        body: {
            accountToken,
            productID,
            variantID,
            quantity,
            variantName
        }
    });
};
