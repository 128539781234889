/* @flow */

import { associateAccountToShopifyCart, getCartToken } from './cart';
import { listenForPhoneInput } from './dom';
import { userIdentifiedEvent } from './event';
import { getAccountToken } from './lib/account';
import { associateAccountFromKlaviyoID } from './partner/klaviyo';
import { subscribeTrackingListeners } from './recovery';

export async function init () : Promise<void> {
    subscribeTrackingListeners();

    const accountToken = getAccountToken();
    const cartToken = await getCartToken();

    if (!cartToken) {
        return;
    }

    if (!accountToken) {
        associateAccountFromKlaviyoID({
            cartToken
        });

        listenForPhoneInput({
            selector: '.klaviyo-form input',
            cartToken
        });
    } else {
        associateAccountToShopifyCart(cartToken);
        userIdentifiedEvent.emit({ accountToken });
    }
}
