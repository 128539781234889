/* @flow */
/** @jsx h */

import { h } from 'preact';
import { createContext, useContext } from 'preact/compat';

import type { TrackingConfig } from './types';

// $FlowFixMe
// eslint-disable-next-line no-empty-function
const TrackingContext = createContext({ trackCheckpoint: () => {} });

// $FlowFixMe
export const useTracking = () => useContext(TrackingContext);

export const fireCheckpointEvent = (event : string, trackingConfig ? : TrackingConfig) => {
    if (trackingConfig && trackingConfig.gtagConfigID && window.gtag) {
        window.gtag('event', event, {
            send_to:    trackingConfig.gtagConfigID,
            experiment: trackingConfig?.experimentLabel
        });
    }
};

type TrackingProviderProps = {|
    trackingConfig : TrackingConfig,
    children : mixed
|};

// $FlowFixMe
export const TrackingProvider = ({ trackingConfig, children } : TrackingProviderProps) => {
    const trackCheckpoint = (event) => {
        fireCheckpointEvent(event, trackingConfig);
    };

    return (
        <TrackingContext.Provider value={ { trackCheckpoint } }>
            {children}
        </TrackingContext.Provider>
    );
};

