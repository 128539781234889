/* @flow */

import { associateShopperAndStoreLocally } from '../api/associate';
import { QUERY_PARAM } from '../constants';

type AssociateAccountFromKlaviyoIDOptions = {|
  cartToken : string
|};

export function associateAccountFromKlaviyoID({ cartToken } : AssociateAccountFromKlaviyoIDOptions) : Promise<string | void> {
    const queryString = new URLSearchParams(window.location.search);
    const klaviyoID = queryString.get(QUERY_PARAM.KLAVIYO_IDENTIFIER);

    if (klaviyoID) {
        return associateShopperAndStoreLocally({ klaviyoID, cartToken });
    }
    
    return Promise.resolve();
}
