/* @flow */
/** @jsx h */

import { h, render as preactRender } from 'preact';

type RenderComponentOptions<Props> = {|
    component : (props : Props) => mixed,
    props : Props,
    container : string | HTMLElement,
    shadowDOM? : boolean,
    newElement? : boolean
|};

export const renderComponent = <Props>({
    component: Component,
    props,
    container,
    shadowDOM = false,
    newElement = false
} : RenderComponentOptions<Props>) : void => {
    if (typeof container === 'string') {
        const containerCandidate = document.querySelector(container);

        if (!containerCandidate) {
            throw new Error(`Can not find container`);
        }

        container = containerCandidate;
    }

    if (newElement) {
        const element = document.createElement('div');
        container.appendChild(element);
        container = element;
    }

    if (!container) {
        throw new Error(`Can not find container`);
    }
    
    const element = shadowDOM
        ? container.attachShadow({ mode: 'closed' })
        : container;

    preactRender(h(Component, props), element);
};

type SimpleExternalComponentOptions<Props> = {|
    component : (props : Props) => mixed,
    shadowDOM? : boolean
|};

export type SimpleExternalComponent<Props> = (
    props : Props
) => {|
    render : (string | HTMLElement) => void
|};

export const simpleExternalComponent = <Props>({
    component,
    shadowDOM = false
} : SimpleExternalComponentOptions<Props>) : SimpleExternalComponent<Props> => {
    const initialize = (props) => {
        const render = (container) => {
            renderComponent({
                component,
                props,
                container,
                shadowDOM
            });
        };
    
        return {
            render
        };
    };

    return initialize;
};
