/* @flow */

import { associateShopperAndStoreLocally } from '../api';
import { getAccountToken } from '../lib';
import { isShopifyStore } from '../partner';

export function associateAccountToShopifyCart(cartToken : string) : void {
    if (isShopifyStore()) {
        const accountToken = getAccountToken();

        if (accountToken) {
            associateShopperAndStoreLocally({
                accountToken,
                cartToken
            });
        }
    }
}
