/* @flow */

import { getSDKAccountToken, getSDKClientID, isDevice, promiseTry } from '../../lib';
import { callOneTextAPI, type CallOneTextAPIResult, constructOneTextURL, redirectInPopup } from '../api';

type StartCustomFlowOptions = {|
    body : mixed
|};

type StartCustomFlowResponse = {|
    
|};

export const startCustomFlow = ({
    body
} : StartCustomFlowOptions) : Promise<CallOneTextAPIResult<StartCustomFlowResponse>> => {
    return callOneTextAPI({
        path: 'flow/start',
        body
    });
};

type GetCustomFlowLinkOptions = {|
    body : mixed
|};

type GetCustomFlowLinkResponse = {|
    link : string,
    threadLink : string
|};

export const getCustomFlowLink = ({
    body
} : GetCustomFlowLinkOptions) : Promise<CallOneTextAPIResult<GetCustomFlowLinkResponse>> => {
    return callOneTextAPI({
        path: 'flow/link',
        body
    });
};
type GetRawCustomFlowLinkOptions = {|
    message? : string,
    email? : string,
    externalID : string,
    parameters? : {
        [ key : string ] : mixed
    },
    forceSMSRedirect? : boolean
|};

export const getRawCustomFlowLink = ({
    externalID,
    message,
    parameters,
    forceSMSRedirect = false
} : GetRawCustomFlowLinkOptions) : string => {
    const accountToken = getSDKAccountToken();
    const clientID = getSDKClientID();

    if (!accountToken && !clientID) {
        throw new Error(`Can not determine clientID`);
    }

    const query : {
        [ key : string ] : string
    } = {
        externalID,
        domain: window.location.origin
    };

    if (parameters) {
        query.parameters = JSON.stringify(parameters);
    }

    if (message) {
        query.message = message;
    }

    if (accountToken) {
        query.accountToken = accountToken;
    }

    if (clientID) {
        query.clientID = clientID;
    }

    if (forceSMSRedirect) {
        query.forceSMSRedirect = 'true';
    }

    return constructOneTextURL({
        path: 'flow/start',
        query
    });
};

type GenerateCustomFlowLinkOptions = {|
    message? : string,
    email? : string,
    externalID : string,
    parameters? : {
        [ key : string ] : mixed
    },
    forceSMSRedirect? : boolean,
    raw ? : boolean
|};

export const generateCustomFlowLink = ({
    message,
    email,
    externalID,
    parameters,
    forceSMSRedirect,
    raw
} : GenerateCustomFlowLinkOptions) : Promise<string> => {
    return promiseTry(() => {
        return raw
            ? getRawCustomFlowLink({
                message,
                email,
                externalID,
                parameters,
                forceSMSRedirect
            })
            : getCustomFlowLink({
                body: {
                    message,
                    externalID,
                    parameters,
                    forceSMSRedirect
                }
            }).then(({ body }) => {
                return body.link;
            });
    });
};

type SendCustomFlowStartMessageOptions = {|
    message? : string,
    email? : string,
    externalID : string,
    parameters? : {
        [ key : string ] : mixed
    },
    forceSMSRedirect? : boolean
|};

export const sendCustomFlowStartMessage = ({
    externalID,
    message,
    parameters,
    forceSMSRedirect = false
} : SendCustomFlowStartMessageOptions) : Promise<void> => {
    return promiseTry(() => {
        if (isDevice()) {
            return redirectInPopup({
                url: getRawCustomFlowLink({
                    externalID,
                    message,
                    parameters,
                    forceSMSRedirect
                })
            });
        }
    });
};

export const sendFlowStartMessage = sendCustomFlowStartMessage;
