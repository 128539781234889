/* @flow */

import { fetchShopifyCart } from '../api';
import { isShopifyStore } from '../partner';

const onCartInitializationHandlers : Array<(cartToken : string) => Promise<void> | void> = [];

const cartTokenCache = {};

export const onCartInitialization = (handler : (cartToken : string) => Promise<void> | void) : void => {
    onCartInitializationHandlers.push(handler);
};

export async function initializeCart() : Promise<string | void> {
    if (isShopifyStore()) {
        return await fetchShopifyCart();
    }
}

export async function getCartToken() : Promise<string | void> {
    const cacheAge = Date.now() - (cartTokenCache.timestamp || 0);

    // Expire cart cache every 24h to esnure no stale tokens
    if (cartTokenCache.token && cacheAge < 86400000) {
        return cartTokenCache.token;
    }

    if (cartTokenCache.promise) {
        return await cartTokenCache.promise;
    }

    const cartTokenPromise = initializeCart();
    cartTokenCache.promise = cartTokenPromise;

    const cartToken = await cartTokenPromise;
    delete cartTokenCache.promise;
    cartTokenCache.token = cartToken;
    cartTokenCache.timestamp = Date.now();
    
    return cartToken;
}
