/* @flow */

type ListenForClickHandlerSignature = {|
    element : HTMLInputElement,
    form : HTMLFormElement
|};

type ListenForClickOptions = {|
    selector : string,
    requireValidForm ? : boolean,
    rootElemenet ? : Node,
    handler : (ListenForClickHandlerSignature) => void
|};

export function listenForClick({ selector, requireValidForm = true, rootElement = document.body, handler } : ListenForClickOptions) : void {
    const observer = new MutationObserver(() => {
        const element = document.querySelector(selector);
        if (element) {
            /* ::
                if (!(element instanceof HTMLInputElement)) {
                    throw new Error('element is not of type HTMLInputElement');
                }
            */
            observer.disconnect();
            const onClick = (evt : Event) => {
                evt.stopPropagation();
                const form = element.form;
                if (form) {
                    if (requireValidForm) {
                        if (form.querySelector('[aria-invalid="true"]')) {
                            return;
                        }
                    }
                    handler({
                        element,
                        form
                    });
                }
            };
            element.addEventListener('click', onClick);
        }
    });

    observer.observe(rootElement, {
        childList: true,
        subtree:   true
    });
}
