
/* @flow */

import { associateShopperAndStoreLocally, submitOptIn } from '../api';
import { getCartToken } from '../cart';
import { noop } from '../lib';

type SubmitOptInOptions = {|
    phone? : string,
    customFlowParameters? : {
        [ key : string ] : mixed
    },
    email? : string
|};

export const submit = ({
    phone,
    email,
    customFlowParameters
} : SubmitOptInOptions) : Promise<void> => {
    const associateShopperPromise = () => {
        if (phone) {
            return getCartToken().then((cartToken) => {
                if (cartToken) {
                    associateShopperAndStoreLocally({ phone, cartToken });
                }
            }).catch(noop);
        }
    };
    const submitOptInPromise = () => {
        return  submitOptIn({
            phone,
            email,
            customFlowParameters,
            requestTwoFactorCode:             false,
            immediatelyInvokeCoreWelcomeFlow: true
        });
    };

    return Promise.all([ associateShopperPromise(), submitOptInPromise() ]).then(noop);
};
