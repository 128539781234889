/* @flow */

type BasicArgs = $ReadOnlyArray<mixed>;

type EventEmitterOptions<Args: BasicArgs> = {|
    onError : ({| args : Args, error : mixed |}) => Promise<void> | void
|};

type Listener<Args: BasicArgs> = (...args: Args) => Promise<void> | void;

export type EventEmitter<Args: BasicArgs> = {|
    on : (listener : Listener<Args>) => {| cancel : () => void |},
    emit : (...args: Args) => void
|};

export const eventEmitter = <Args: BasicArgs>({ onError } : EventEmitterOptions<Args>) : EventEmitter<Args> => {
    const listeners = new Set<Listener<Args>>();

    const on = (listener : Listener<Args>) : {| cancel : () => void |} => {
        listeners.add(listener);
        return {
            cancel: () => {
                listeners.delete(listener);
            }
        };
    };

    const emit = (...args : Args) : void => {
        listeners.forEach(async (listener) => {
            try {
                await listener(...args);
            } catch (error) {
                await onError({
                    args,
                    error
                });
            }
        });
    };

    return {
        on,
        emit
    };
};
