/* @flow */

import { request } from '../lib';

export const SHOPIFY_PAGE_TYPE = {
    PRODUCT: ('product' : 'product'),
    CART:    ('cart' : 'cart'),
    PAGE:    ('page' : 'page')
};

function getShopName() : ?string {
    return window?.Shopify?.shop;
}

function getPageType() : ?$Values<typeof SHOPIFY_PAGE_TYPE> {
    const pageType = window?.meta?.page?.pageType;

    if (pageType) {
        return pageType;
    }

    if (location.pathname.match(/^\/products\/[^?/]+/)) {
        return SHOPIFY_PAGE_TYPE.PRODUCT;
    }

    if (location.pathname.indexOf('/cart') === 0) {
        return SHOPIFY_PAGE_TYPE.CART;
    }
}

function getSelectedVariantID() : ?string {
    const variants = window?.meta?.product?.variants;
    if (variants?.length === 1) {
        return variants[0].id.toString();
    }
    
    // $FlowFixMe
    return document.querySelector('[name=id]')?.value?.toString();
}

function getSelectedVariantQuantity() : number {
    // $FlowFixMe
    const quantity = document.querySelector('[name=quantity]')?.value ?? '1';
    const integerRegex = /^\d+$/;
  
    if (integerRegex.test(quantity)) {
        return parseInt(quantity, 10);
    }
    
    return 1;
}

function getSelectedVariantName() : ?string {
    const variantID = getSelectedVariantID();
    return window?.meta?.product?.variants?.find(variant => variant.id.toString() === variantID)?.name;
}

function getSelectedProductID() : ?string {
    const productCandidate = window?.meta?.product?.id?.toString() ?? window?.product?.id?.toString();
    
    const integerRegex = /^\d+$/;
  
    if (productCandidate && integerRegex.test(productCandidate)) {
        return productCandidate;
    }
}

export function isShopifyStore() : boolean {
    return Boolean(window?.Shopify);
}

export type ShopifyPageData = {|
    shopName : string,
    pageType : $Values<typeof SHOPIFY_PAGE_TYPE>,
    productID ? : string,
    items : $ReadOnlyArray<{|
        variantID : string,
        variantName ? : string,
        quantity : number
    |}>
|};

export function getShopifyPageData() : Promise<?ShopifyPageData> {
    return Promise.resolve().then(() => {
        const shopName = getShopName();
        const pageType = getPageType();
        const selectedVariantID = getSelectedVariantID();
        const selectedVariantQuantity = getSelectedVariantQuantity();
        const selectedProductID = getSelectedProductID();
        const selectedVariantName = getSelectedVariantName();
    
        if (!isShopifyStore() || !shopName || !pageType) {
            return;
        }
    
        if ((pageType === SHOPIFY_PAGE_TYPE.PRODUCT
            || pageType === SHOPIFY_PAGE_TYPE.PAGE)
            && selectedProductID
        ) {
            return {
                shopName,
                pageType,
                productID: selectedProductID,
                items:     selectedVariantID
                    ? [
                        {
                            variantID:   selectedVariantID,
                            variantName: selectedVariantName ?? undefined,
                            quantity:    selectedVariantQuantity
                        }
                    ]
                    : []
            };
        }
    
        if (pageType === SHOPIFY_PAGE_TYPE.CART) {
            return request({
                url: '/cart.js'
            }).then(({ body }) => {
                if (!body?.items?.length) {
                    return;
                }

                return {
                    shopName,
                    pageType: SHOPIFY_PAGE_TYPE.CART,
                    items:    body.items.map(item => {
                        return {
                            variantID: item.variant_id.toString(),
                            quantity:  item.quantity
                        };
                    })
                };
            });
        }
    });
}
