/* @flow */

import { getSDKAccountToken, getSDKClientID } from '../lib';

import { callOneTextAPI, type CallOneTextAPIResult, constructOneTextURL, redirectInPopup } from './api';

type GetSwitchToSMSThreadLinkOptions = {|
    message? : string
|};

type GetSwitchToSMSThreadLinkResponse = {|
    link : string
|};

export const getSwitchToSMSThreadLink = ({
    message
} : GetSwitchToSMSThreadLinkOptions = {}) : Promise<CallOneTextAPIResult<GetSwitchToSMSThreadLinkResponse>> => {
    return callOneTextAPI({
        path: 'messages/thread/switch',
        body: {
            message
        }
    });
};

type GetBasicSwitchToSMSThreadLinkOptions = {|
    message? : string
|};

export const getBasicSwitchToSMSThreadLink = ({
    message
} : GetBasicSwitchToSMSThreadLinkOptions = {}) : string => {
    const accountToken = getSDKAccountToken();
    const clientID = getSDKClientID();

    if (!accountToken && !clientID) {
        throw new Error(`Can not determine clientID`);
    }

    const query : {
            [ key : string ] : string
        } = {};

    if (message) {
        query.message = message;
    }

    if (accountToken) {
        query.accountToken = accountToken;
    }

    if (clientID) {
        query.clientID = clientID;
    }

    return constructOneTextURL({
        path:  'sms/switch',
        query
    });
};

type SwitchToSMSThreadOptions = {|
    message? : string
|};

export const switchToSMSThread = ({
    message
} : SwitchToSMSThreadOptions = {}) : void => {
    redirectInPopup({
        url: getBasicSwitchToSMSThreadLink({ message })
    });
};
